import {
  useRef,
  useState,
  useEffect
} from 'react';
import db from '@services/firebase-service';
import {
  doc,
  query,
  addDoc,
  orderBy,
  onSnapshot,
  collection,
  limitToLast
} from 'firebase/firestore';
import ChatHeader from '@components/Sandbox/Ipa/chat/header';
import ChatFooter from '@components/Sandbox/Ipa/chat/footer';
import ChatMessage from '@components/Sandbox/Ipa/chat/message';
import { useHotspot } from '@lib/hooks/useHotSpot';
import { Pulse } from '@atoms/Pulse';
import ScrollableFeed from 'react-scrollable-feed';

const ChatBox = ({ streamId, featureId, interactionType, showInputPulse }) => {
  const { isHotspotEnabled, handleHotspotClose } = useHotspot('sandbox:chat-input:hotspot');
  const [messages, setMessages] = useState([]);
  const userName = 'You';
  const activeUserId = 'user';
  const [eventMetaData, setEventMetaData] = useState({
    activeInteraction: null
  });
  const featureType = useRef(null);

  useEffect(() => {
    featureType.current = interactionType;
  }, [interactionType]);

  // eslint-disable-next-line camelcase
  const onComment = async ({ chat_input }) => {
    const rawMessagesRef = collection(db, 'streams', streamId, 'rawMessages');

    const document = {
      upvotes: 0,
      // eslint-disable-next-line camelcase
      message: chat_input,
      time: Date.now(),
      userId: activeUserId,
      name: userName,
      interaction: interactionType
    };

    setMessages((prev) => [...prev, document]);

    await addDoc(rawMessagesRef, document);
  };

  useEffect(() => {
    if (!streamId) return undefined;

    const eventMetaDataDocRef = doc(
      db,
      'streams',
      streamId,
      'eventMetaData',
      streamId
    );

    const unSubMetaData = onSnapshot(
      query(eventMetaDataDocRef),
      (querySnapshot) => {
        setEventMetaData((prev) => ({
          ...prev,
          ...querySnapshot.data()
        }));
      }
    );

    const unSubMessages = onSnapshot(
      query(
        collection(db, 'streams', streamId, 'processedMessages'),
        orderBy('time'),
        limitToLast(20)
      ),
      (querySnapshot) => {
        querySnapshot.docChanges().forEach((change) => {
          if (change.type === 'added') {
            const document = {
              ...change.doc.data(),
              id: change.doc.id
            };

            if (
              document?.name === userName
              && document?.userId === activeUserId
            ) { return; }

            if (featureType.current !== document?.interaction) return;

            setMessages((prev) => [...prev, document]);
          }
        });
      }
    );

    return () => {
      unSubMetaData();
      unSubMessages();
    };
  }, [streamId]);

  useEffect(() => {
    setMessages([]);
  }, [featureId]);

  const changeDetectionFilter = (previousProps, newProps) => {
    const prevChildren = previousProps.children;
    const newChildren = newProps.children;

    return prevChildren !== newChildren
      && prevChildren[prevChildren.length - 1] !== newChildren[newChildren.length - 1];
  };

  return (<div
    className='flex flex-col w-full'
    style={{
      backgroundColor: '#151515',
      backgroundImage: 'url("/images/sandbox/BACKGROUND2.svg")'
    }}
  >
    <ChatHeader />
    <ScrollableFeed
      className='pt-2 flex-1'
      changeDetectionFilter={changeDetectionFilter}
    >
      {messages.map((message) => (
        <div
          key={message.id}
          className={`flex flex-col ${userName === message?.name ? 'items-end' : 'items-start'}`}
        >
          <ChatMessage
            {...message}
            streamId={streamId}
            isSelf={userName === message?.name}
          />
        </div>
      ))}
    </ScrollableFeed>

    <div className='relative'>
      <ChatFooter
        onComment={onComment}
        activeInteraction={eventMetaData.activeInteraction}
      />
      {showInputPulse && isHotspotEnabled && (
        <div className='absolute bottom-10 left-28'>
          <Pulse
            isEnabled={isHotspotEnabled}
            onClose={handleHotspotClose}
            tooltip='Please type your response here; it will reflect in the interaction.'
          />
        </div>
      )}
    </div>
  </div>
  );
};

export default ChatBox;
