import { useEffect, useState } from 'react';

export default function useDeviceOrientation() {
  const hasWindow = typeof window !== 'undefined';

  const [isMobile, setIsMobile] = useState(false);
  const [orientation, setOrientation] = useState('');

  const updateOrientation = () => {
    if (window.matchMedia('(orientation: portrait)').matches) {
      setOrientation('Portrait');
    } else if (window.matchMedia('(orientation: landscape)').matches) {
      setOrientation('Landscape');
    }
  };

  useEffect(() => {
    if (!hasWindow) return undefined;

    const isMobileDevice = /iPhone|iPad|iPod|Android|webOS|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    setIsMobile(isMobileDevice);

    updateOrientation();

    window.addEventListener('resize', updateOrientation);

    return () => {
      window.removeEventListener('resize', updateOrientation);
    };
  }, [hasWindow]);

  return { isMobile, orientation };
}
