import {
  useRef,
  useState,
  useEffect
} from 'react';
import * as yup from 'yup';
import db from '@services/firebase-service';
import { yupValidator } from '@lib/yup-validator';
import {
  query,
  addDoc,
  orderBy,
  onSnapshot,
  collection,
  limitToLast
} from 'firebase/firestore';
import { useForm } from 'react-hook-form';
import { pushDataLayerForEvent } from '@lib/gtag';
import { Pulse } from '@atoms/Pulse';
import { useHotspot } from '@lib/hooks/useHotSpot';
import ScrollableFeed from 'react-scrollable-feed';
import { Input } from '@nextui-org/input';
import { SentimentSatisfiedOutlined as EmojiIcon } from '@mui/icons-material';
import Image from 'next/image';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

const ChatBox = ({ streamId, featureId, interactionType, showInputPulse }) => {
  const { isHotspotEnabled, handleHotspotClose } = useHotspot('sandbox:chat-input:hotspot');

  const [messages, setMessages] = useState([]);
  const userName = 'You';
  const activeUserId = 'user';
  const featureType = useRef(null);

  useEffect(() => {
    featureType.current = interactionType;
  }, [interactionType]);

  // eslint-disable-next-line camelcase
  const onComment = async ({ chat_input }) => {
    const rawMessagesRef = collection(db, 'streams', streamId, 'rawMessages');

    const document = {
      upvotes: 0,
      // eslint-disable-next-line camelcase
      message: chat_input,
      time: Date.now(),
      userId: activeUserId,
      name: userName,
      interaction: interactionType
    };

    setMessages((prev) => [...prev, document]);

    await addDoc(rawMessagesRef, document);
  };

  useEffect(() => {
    if (!streamId) return undefined;

    const unSubMessages = onSnapshot(
      query(
        collection(db, 'streams', streamId, 'processedMessages'),
        orderBy('time'),
        limitToLast(20)
      ),
      (querySnapshot) => {
        querySnapshot.docChanges().forEach((change) => {
          if (change.type === 'added') {
            const document = {
              ...change.doc.data(),
              id: change.doc.id
            };

            if (
              document?.name === userName
              && document?.userId === activeUserId
            ) { return; }

            if (featureType.current !== document?.interaction) return;

            setMessages((prev) => [...prev, document]);
          }
        });
      }
    );

    return () => {
      unSubMessages();
    };
  }, [streamId]);

  const {
    register,
    handleSubmit,
    setFocus,
    reset,
    watch
  } = useForm({
    mode: 'onChange',
    resolver: yupValidator(
      yup.object().shape({
        chat_input: yup.string().required()
      })
    )
  });

  useEffect(() => {
    setFocus('chat_input');
  }, []);

  const handleComment = (data) => {
    pushDataLayerForEvent('sandbox_linkedin_chatbox_send_message', { streamId }, false);
    onComment(data);
    reset({ chat_input: '' });
    setFocus('chat_input');
  };

  useEffect(() => {
    setMessages([]);
  }, [featureId]);

  const input = watch('chat_input');

  return (<div className='w-full flex flex-col'>
    <div className='px-4 border-b border-grey-200'>
      <div className="flex justify-between items-center">
        <p
          style={{ color: '#01754F' }}
          className="font-semibold text-sm flex justify-between items-center border-b border-[#01754F] py-2">
          Comments
        </p>
        <div />
      </div>
    </div>

    <ScrollableFeed className='p-4 flex-1 scrollbar-hide'>
      {messages.map((message) => (
        <Message key={message.id} message={message} />
      ))}
    </ScrollableFeed>

    <form
      onSubmit={handleSubmit(handleComment)}
      className='px-4 py-2 flex items-center justify-between bg-[#F8FAFD]'>
      <div className='relative flex items-center gap-2 w-full'>
        <Image
          className='rounded-full'
          alt='user'
          src='/images/sandbox/linkedin/user.png'
          width={32}
          height={32}
        />
        <Input
          autoComplete='off'
          variant='bordered'
          radius='full'
          value={input}
          classNames={{
            inputWrapper: 'bg-white'
          }}
          {...register('chat_input')}
          className="w-full font-medium text-neutral-60"
          placeholder='Add a comment…'
          endContent={(
            <EmojiIcon fontSize='small' />
          )}
        />
        {showInputPulse && isHotspotEnabled && (
          <div className='absolute -top-6 left-24'>
            <Pulse
              isEnabled={isHotspotEnabled}
              onClose={handleHotspotClose}
              tooltip='Please type your response here; it will reflect in the interaction.'
            />
          </div>
        )}
      </div>

      <input type="submit" hidden />

    </form>
  </div>
  );
};

const Message = ({ message }) => (
  <div className='my-2 flex gap-2'>
    <Image
      className='rounded-full'
      alt='user'
      src='/images/sandbox/linkedin/user.png'
      width={40}
      height={40}
      style={{
        maxHeight: '40px',
        maxWidth: '40px'
      }}
    />

    <div className='w-full'>
      <div className='flex flex-col font-medium bg-[#EDF3F8] px-3 py-2 rounded-lg rounded-tl-none w-full ' >
        <p className='text-black/90 text-small'>{message.name}</p>
        <p className='text-black/60 text-xs'>Lead at XYZ company</p>
        <p
          style={{ wordWrap: 'anywhere' }}
          className='font-medium text-xs'
        >
          {message.message}
        </p>
      </div>

      <div className='flex items-center gap-2 px-3'>
        <Image
          alt='reply'
          src='/images/sandbox/linkedin/chat.svg'
          width={16}
          height={16}
        />
        <p className='text-black/75 text-sm my-0.5 font-semibold border-r pr-2 border-black/30'>Reply</p>
        <MoreHorizIcon fontSize='small' className='text-black/60' />
      </div>
    </div>
  </div>
);

export default ChatBox;
