import { useEffect, useState } from 'react';

export const useHotspot = (key: string) => {
  const [isHotspotEnabled, setIsHotspotEnabled] = useState(false);

  useEffect(() => {
    const isHotspotOpen = localStorage.getItem(key);
    if (isHotspotOpen !== 'false') {
      setIsHotspotEnabled(true);
    }
  }, []);

  const handleHotspotClose = () => {
    localStorage.setItem(key, 'false');
    setIsHotspotEnabled(false);
  };

  return { isHotspotEnabled, handleHotspotClose };
};
