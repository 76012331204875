import {
  useRef,
  useState,
  useEffect
} from 'react';
import moment from 'moment';
import * as yup from 'yup';
import db from '@services/firebase-service';
import { yupValidator } from '@lib/yup-validator';
import {
  query,
  addDoc,
  orderBy,
  onSnapshot,
  collection,
  limitToLast
} from 'firebase/firestore';
import { useForm } from 'react-hook-form';
import { pushDataLayerForEvent } from '@lib/gtag';
import { MdSend as SendIcon } from 'react-icons/md';
import { Pulse } from '@atoms/Pulse';
import { useHotspot } from '@lib/hooks/useHotSpot';
import ScrollableFeed from 'react-scrollable-feed';
import { Input } from '@nextui-org/input';
import Image from 'next/image';
import { stringToColor } from '../Youtube/ChatBox';
import { SettingsRounded } from '@mui/icons-material';

const ChatBox = ({ streamId, featureId, interactionType, showInputPulse }) => {
  const { isHotspotEnabled, handleHotspotClose } = useHotspot('sandbox:chat-input:hotspot');

  const [messages, setMessages] = useState([]);
  const userName = 'You';
  const activeUserId = 'user';
  const featureType = useRef(null);

  useEffect(() => {
    featureType.current = interactionType;
  }, [interactionType]);

  // eslint-disable-next-line camelcase
  const onComment = async ({ chat_input }) => {
    const rawMessagesRef = collection(db, 'streams', streamId, 'rawMessages');

    const document = {
      upvotes: 0,
      // eslint-disable-next-line camelcase
      message: chat_input,
      time: Date.now(),
      userId: activeUserId,
      name: userName,
      interaction: interactionType
    };

    setMessages((prev) => [...prev, document]);

    await addDoc(rawMessagesRef, document);
  };

  useEffect(() => {
    if (!streamId) return undefined;

    const unSubMessages = onSnapshot(
      query(
        collection(db, 'streams', streamId, 'processedMessages'),
        orderBy('time'),
        limitToLast(20)
      ),
      (querySnapshot) => {
        querySnapshot.docChanges().forEach((change) => {
          if (change.type === 'added') {
            const document = {
              ...change.doc.data(),
              id: change.doc.id
            };

            if (
              document?.name === userName
              && document?.userId === activeUserId
            ) { return; }

            if (featureType.current !== document?.interaction) return;

            setMessages((prev) => [...prev, document]);
          }
        });
      }
    );

    return () => {
      unSubMessages();
    };
  }, [streamId]);

  const {
    register,
    handleSubmit,
    setFocus,
    reset,
    watch
  } = useForm({
    mode: 'onChange',
    resolver: yupValidator(
      yup.object().shape({
        chat_input: yup.string().required()
      })
    )
  });

  useEffect(() => {
    setFocus('chat_input');
  }, []);

  const handleComment = (data) => {
    pushDataLayerForEvent('sandbox_linkedin_chatbox_send_message', { streamId }, false);
    onComment(data);
    reset({ chat_input: '' });
    setFocus('chat_input');
  };

  useEffect(() => {
    setMessages([]);
  }, [featureId]);

  const input = watch('chat_input');

  return (<div className='w-full flex flex-col bg-[#141517]'>
    <div className='px-4 border-b border-[#24272C]'>
      <div className="flex justify-between items-center">
        <Image
          alt='kick-logo'
          src='/images/sandbox/kick/chat-header.svg'
          width={32}
          height={32}
        />
        <p
          className="font-semibold text-white text-sm flex justify-between items-center py-2">
          Chat
        </p>
        <div />
      </div>
    </div>

    <ScrollableFeed className='p-4 flex-1 scrollbar-hide'>
      {messages.map((message) => (
        <Message key={message.id} message={message} />
      ))}
    </ScrollableFeed>

    <form
      onSubmit={handleSubmit(handleComment)}
      className='px-4 py-2 flex flex-col items-end gap-2 justify-between'>
      <div className='relative flex items-center gap-2 w-full'>
        <Input
          autoComplete='off'
          variant='bordered'
          radius='sm'
          value={input}
          {...register('chat_input')}
          className="w-full font-medium text-neutral-60"
          placeholder='Send a message'
          endContent={(
            <Image
              alt='send-icon'
              src='/images/sandbox/kick/smiley.svg'
              width={32}
              height={32}
            />
          )}
        />
        {showInputPulse && isHotspotEnabled && (
          <div className='absolute -top-6 left-24'>
            <Pulse
              isEnabled={isHotspotEnabled}
              onClose={handleHotspotClose}
              tooltip='Please type your response here; it will reflect in the interaction.'
            />
          </div>
        )}
      </div>

      <input type="submit" hidden />

      <div className='flex items-center gap-2'>
        <div className='cursor-not-allowed p-[9px]'>
          <SettingsRounded fontSize='small' htmlColor='#fff' />
        </div>
        <button type='submit' className='bg-[#53FC18] text-black px-3 py-1.5 rounded-lg'>Chat</button>
      </div>
    </form>
  </div>
  );
};

const Message = ({ message }) => (
  <div className='mb-2'>
    <p style={{
      fontSize: '14px',
      lineHeight: '18px'
    }} className='font-normal '>
      <span style={{
        color: stringToColor(message.name)
      }} className='mr-1'>{message.name}</span>
      <span className='text-white' style={{
        wordWrap: 'anywhere'
      }}>{message.message}</span>
    </p>
  </div>
);

export default ChatBox;
