import Image from '@atoms/Image';

export default function RequestLandscapeModal({ visible }) {
  if (!visible) return null;

  return (
    <div className='fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 z-50 flex items-center justify-center'>
      <Image
        className="w-1/2"
        alt={'Request Landscape'}
        src="/images/sandbox/RequestLandscape.svg"
      />
    </div>
  );
}
