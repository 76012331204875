import moment from 'moment';
import Text from '@atoms/Text';

export const chatColors = [
  { name: 'LightCoral', hexCode: '#F08080' },
  { name: 'DeepPink', hexCode: '#FF1493' },
  { name: 'Fuchsia', hexCode: '#FF00FF' },
  { name: 'Tomato', hexCode: '#FF6347' },
  { name: 'LemonChiffon', hexCode: '#FFFACD' },
  { name: 'PaleTurquoise', hexCode: '#AFEEEE' },
  { name: 'LightPink', hexCode: '#FFB6C1' },
  { name: 'NavajoWhite', hexCode: '#FFDEAD' },
  { name: 'Khaki', hexCode: '#F0E68C' },
  { name: 'PeachPuff', hexCode: '#FFDAB9' },
  { name: 'Plum', hexCode: '#DDA0DD' },
  { name: 'PaleVioletRed', hexCode: '#DB7093' },
  { name: 'PowderBlue', hexCode: '#B0E0E6' },
  { name: 'BlanchedAlmond', hexCode: '#FFEBCD' },
  { name: 'PastelPurple', hexCode: '#F2A2E8' },
  { name: 'MediumSlateBlue', hexCode: '#7B68EE' },
  { name: 'DarkSalmon', hexCode: '#E9967A' },
  { name: 'Crimson', hexCode: '#DC143C' },
  { name: 'MistyRose', hexCode: '#FFE4E1' },
  { name: 'MediumVioletRed', hexCode: '#C71585' }
];

const ChatMessage = ({
  name = '',
  message,
  time,
  isSelf
}) => {
  // eslint-disable-next-line no-bitwise
  const hashCode = (str) => str?.split('').reduce((prevHash, currVal) => (((prevHash << 5) - prevHash) + currVal.charCodeAt(0)) | 0, 0);

  const getChatColor = (username = '') => {
    if (!username) return '#F08080';

    const hash = Math.abs(hashCode(username));
    const first2Str = String(hash).slice(0, 2);
    const first2Num = Number(first2Str);
    const chatColor = chatColors[(first2Num) % chatColors.length].hexCode;
    return chatColor;
  };

  return (
    <div
      style={isSelf ? { backgroundColor: '#000000', paddingTop: '0.75rem' } : { backgroundColor: '#28282F' }}
      className={`min-w-25 rounded-t-xl ${!isSelf ? 'rounded-br-2xl rounded-tr-2xl' : 'bg-primary-50 rounded-bl-2xl rounded-tl-2xl'} px-2.5 pt-2.5 pb-1.5 mb-2.5 mx-2.5`}
    >
      {!isSelf && <span style={{ color: `${getChatColor(name)}` }} className="font-OpenSans font-semibold text-xs leading-4 p-0">
        {name}
      </span>}

      <div className="relative flex flex-col justify-between text-white">
        <Text className="mb-5.5 font-OpenSans font-semibold text-sm leading-5 break-all text-left">
          {message}
        </Text>
        <span className="absolute bottom-1 right-1 font-OpenSans font-semibold text-tiny leading-3 text-neutral-30 pl-2 pt-1.5">
          {moment(time).format('hh:mm A')}
        </span>
      </div>
    </div>
  );
};

export default ChatMessage;
