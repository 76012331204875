import {
    Textarea as NextUITextarea,
    type TextAreaProps as NextUITextareaProps,
  } from '@nextui-org/input';
  import { forwardRef } from '@nextui-org/react';
  
  export interface CustomTextareaProps extends NextUITextareaProps {
    state?: 'valid' | 'invalid' | 'default';   
  }
  
  const Textarea = forwardRef(
    ({ state = 'default', classNames = {}, ...rest }: CustomTextareaProps, ref) => {
      const states = {
        valid:
          'border-green-300 hover:border-green-300 group-data-[focus=true]:border-green-300',
        invalid:
          'border-red-500 hover:border-red-500 group-data-[focus=true]:border-red-500',
        default:
          'border-grey-400 hover:border-blue-500 group-data-[focus=true]:border-blue-500 group-data-[focus=true]:shadow-[0_0_0_1px_inset_rgba(67,139,247)]',
      }[state];
  
      return (
        <NextUITextarea
          ref={ref}
          {...rest}
          variant={rest.readOnly ? 'faded' : 'bordered'}
          classNames={{
            label: 'text-sm font-semibold text-grey-900 select-none',
            inputWrapper: `border rounded-md shadow-none px-2  ${
                rest.readOnly ? 'border-grey-400 bg-grey-50' : states
              }`,
            input: 'text-sm placeholder:text-grey-400 px-2',
            ...classNames
          }}
        />
      );
    }
  );
  
  export default Textarea;
  