import { useEffect, useState, useRef } from 'react';
import { getRandomInt } from '@lib/random';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';

const images = [
  '/images/ai-loader/map.svg',
  '/images/ai-loader/poll.svg',
  '/images/ai-loader/questions.svg',
  '/images/ai-loader/rating-poll.svg',
  '/images/ai-loader/talking-tiles.svg',
  '/images/ai-loader/thoughts.svg',
  '/images/ai-loader/winning-wheel.svg',
  '/images/ai-loader/word-cloud.svg'
];

const AILoader = () => {
  const progress = useRef(25);
  const [index, setIndex] = useState(getRandomInt(0, images.length));

  useEffect(() => {
    let indexInterval = setInterval(() => {
      setIndex((prev) => (prev + 1) % images.length);
    }, 1000);

    const progressInterval = setInterval(() => {
      if (progress.current <= 75) {
        progress.current += 25;
      }
    }, 3000);

    return () => {
      clearInterval(indexInterval);
      clearInterval(progressInterval);
    };
  }, []);

  return (
    <div className='bg-white w-screen h-screen z-50'>
      <div className='h-full flex flex-col items-center justify-center'>
        <img
          style={{
            height: '264px'
          }}
          src={images[index]}
          alt="Loading"
        />

        <div className='mt-16'>
          <ProgressBar progress={progress.current} />
        </div>
      </div>
    </div>
  );
};

const ProgressBar = ({ progress }: any) => {
  return (
    <div className="relative w-[800px] h-[60px] rounded-lg overflow-hidden">
      <div
        className=" transition-width h-full bg-gradient-to-r from-pink-400 via-purple-400 to-blue-400"
        style={{ width: `${progress}%` }}
      />

      <div className="absolute inset-0 flex items-center justify-center">
        <AutoAwesomeIcon fontSize='small' className="mr-1" />
        <p className="font-medium text-2xl text-grey-800">
          AI is generating simulated comments for your preview. Hang tight!
        </p>
      </div>
    </div>
  );
};

export default AILoader;
