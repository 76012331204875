import Text from '@atoms/Text';

const InteractionScreenBanner = ({ activeInteraction, interactionDetails: { Icon = <></>, name = '', hint = null } }) => (
  <div className=" w-full bg-black flex items-center px-2 py-4">
    <div className="mr-2 w-15 h-15 flex items-center bg-gray-30 px-2.5 py-3 rounded-lg">
      {Icon}
    </div>
    <div className="flex-1">
      <Text className="font-medium md:text-xs text-tiny leading-3 text-black">
        <span className="uppercase" style={{ color: '#F97B5F' }}>
          {name}
        </span>
        <span className="font-medium text-tiny leading-3 text-slate-400 uppercase ml-2">{!!hint && hint}</span>
      </Text>

      <Text className="max-w-64 w-full font-normal text-base leading-5 line-clamp-2 text-white mt-1">
        {activeInteraction?.question}
      </Text>
    </div>
  </div>
);

export default InteractionScreenBanner;
