export const hybridPlatformMap = {
  twitch: 'twitch',
  zoom: 'zoom',
  'google meet': 'meet',
  youtube: 'youtube',
  'microsoft teams': 'teams',
  vimeo: 'vimeo',
  webex: 'webex',
  linkedin: 'linkedin',
};

export const getGetHybridPlatformSlug = (
  hybridPlatform?: keyof typeof hybridPlatformMap
) => {
  if (!hybridPlatform) return undefined;
  return hybridPlatformMap[hybridPlatform] as keyof typeof PLATFORMS;
};

export const PLATFORMS = {
  all: {
    order: 0,
    title: 'All',
    slug: 'all',
    logo: '/images/all_platforms.svg',
    sandboxOnly: false,
  },
  hybrid: {
    logo: '/images/hybrid.svg',
    title: 'Hybrid',
    order: 8,
    slug: 'hybrid',
    sandboxOnly: false,
  },
  twitch: {
    logo: '/images/twitch-icon.svg',
    title: 'Twitch',
    order: 6,
    slug: 'twitch',
    sandboxOnly: false,
  },
  zoom: {
    logo: '/images/Zoom.svg',
    title: 'Zoom',
    order: 1,
    slug: 'zoom',
    sandboxOnly: false,
  },
  ipa: {
    logo: '/images/in_person.svg',
    title: 'In-person',
    order: 7,
    slug: 'ipa',
    sandboxOnly: false,
  },
  meet: {
    logo: '/images/meet-icon.svg',
    title: 'Google Meet',
    order: 2,
    slug: 'meet',
    sandboxOnly: false,
  },
  youtube: {
    logo: '/images/youtube-icon.svg',
    title: 'YouTube',
    order: 3,
    slug: 'youtube',
    sandboxOnly: false,
  },
  teams: {
    logo: '/images/teams-logo.svg',
    title: 'Microsoft Teams',
    order: 4,
    slug: 'teams',
    sandboxOnly: false,
  },
  vimeo: {
    logo: '/images/vimeo-icon.svg',
    title: 'Vimeo',
    order: 5,
    slug: 'vimeo',
    sandboxOnly: false,
  },
  webex: {
    logo: '/images/webex-icon.svg',
    title: 'Webex',
    order: 10,
    slug: 'webex',
    sandboxOnly: false,
  },
  'other-online-events': {
    logo: '/images/OtherOnlinePlatforms.svg',
    title: 'Other online platforms',
    order: 9,
    slug: 'other-online-events',
    sandboxOnly: false,
  },
  linkedin: {
    logo: '/images/linkedin-icon.svg',
    title: 'LinkedIn',
    order: 11,
    slug: 'linkedin',
    sandboxOnly: true,
  },
  streamyard: {
    logo: '/images/streamyard.png',
    title: 'Streamyard',
    order: 12,
    slug: 'streamyard',
    sandboxOnly: true,
  },
  restream: {
    logo: '/images/restream.svg',
    title: 'Restream',
    order: 13,
    slug: 'restream',
    sandboxOnly: true,
  },
  airmeet: {
    logo: '/images/airmeet.svg',
    title: 'Airmeet',
    order: 14,
    slug: 'airmeet',
    sandboxOnly: true,
  },
  kick: {
    logo: '/images/kick.png',
    title: 'Kick',
    order: 15,
    slug: 'kick',
    sandboxOnly: true,
  },
};
