import { type InteractionType } from '@components/Presentation/types';
import { useEffect, useRef, useState } from 'react';
import {
  addDoc,
  collection,
} from 'firebase/firestore';
import db from '@services/firebase-service';
import { logsToAwsCloudWatch } from '@services/logger.service';

interface Comment {
  index: number;
  user: string;
  message: string;
  batch: number;
}

interface CommentResponse {
  question: string;
  type: InteractionType;
  response: Comment[];
}

interface UseGeneratedCommentProps {
  platform: string;
  streamId: string;
  responses: CommentResponse[]
  currentRosFeature: { title: string; type: InteractionType };
}

export const useGeneratedComments = ({
  platform,
  streamId,
  responses,
  currentRosFeature,
}: UseGeneratedCommentProps) => {
  const currentResponsePointerRef = useRef(0);
  const [commentIntervalId, setCommentIntervalId] = useState<any>();
  const currentQuestionTitleRef = useRef<string>(currentRosFeature?.title);
  const currentFeatureTypeRef = useRef<InteractionType>(currentRosFeature?.type);

  const onComment = async ({ message, user, interaction }: { message: string, user: string, interaction: InteractionType }) => {
    if (!message || !user || !interaction) return;

    const rawMessagesRef = collection(db, 'streams', streamId, 'rawMessages');

    const document = {
      upvotes: 0,
      message,
      time: Date.now(),
      userId: user,
      name: user,
      interaction
    };

    try {
      await addDoc(rawMessagesRef, document);
    } catch (error: any) {
      logsToAwsCloudWatch(error?.message);
    }
  };

  useEffect(() => {
    if (!responses) return;
    if (!currentRosFeature) return;
    if (!streamId) return;

    clearInterval(commentIntervalId);

    currentQuestionTitleRef.current = currentRosFeature.title;
    currentFeatureTypeRef.current = currentRosFeature.type;
    currentResponsePointerRef.current = 0;

    const feature = responses.find((res) => res.question === currentRosFeature.title && res.type === currentRosFeature.type);

    if (!feature) return;

    const intervalId = setInterval(() => {
      if (currentResponsePointerRef.current >= 90) return;
      const comment = feature.response.find((value => value.batch === currentResponsePointerRef.current));

      if (comment) {
        onComment({ ...comment, interaction: currentFeatureTypeRef.current });
      };
      currentResponsePointerRef.current += 1;
    }, 1000);

    setCommentIntervalId(intervalId);

    return () => clearInterval(intervalId);
  }, [currentRosFeature, responses, streamId, platform]);
};
