import {
  useRef,
  useState,
  useEffect
} from 'react';
import moment from 'moment';
import * as yup from 'yup';
import db from '@services/firebase-service';
import { yupValidator } from '@lib/yup-validator';
import {
  query,
  addDoc,
  orderBy,
  onSnapshot,
  collection,
  limitToLast
} from 'firebase/firestore';
import { useForm } from 'react-hook-form';
import { pushDataLayerForEvent } from '@lib/gtag';
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import { getFontColor } from '@components/Features/talkingTiles/helper';
import { stringToColor } from '../Youtube/ChatBox';
import { Pulse } from '@atoms/Pulse';
import { useHotspot } from '@lib/hooks/useHotSpot';
import ScrollableFeed from 'react-scrollable-feed';
import SendRoundedIcon from '@mui/icons-material/SendRounded';
import { Switch, Input, Avatar } from '@nextui-org/react';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import Textarea from '@atoms/new/textarea';

const ChatBox = ({ streamId, featureId, interactionType, showInputPulse }) => {
  const { isHotspotEnabled, handleHotspotClose } = useHotspot('sandbox:chat-input:hotspot');
  const [messages, setMessages] = useState([]);
  const userName = 'You';
  const activeUserId = 'user';
  const featureType = useRef(null);
  const [overlay, setOverlay] = useState(false);

  useEffect(() => {
    featureType.current = interactionType;
  }, [interactionType]);

  // eslint-disable-next-line camelcase
  const onComment = async ({ chat_input }) => {
    const rawMessagesRef = collection(db, 'streams', streamId, 'rawMessages');

    const document = {
      upvotes: 0,
      // eslint-disable-next-line camelcase
      message: chat_input,
      time: Date.now(),
      userId: activeUserId,
      name: userName,
      interaction: interactionType
    };

    setMessages((prev) => [...prev, document]);

    await addDoc(rawMessagesRef, document);
  };

  useEffect(() => {
    if (!streamId) return undefined;

    const unSubMessages = onSnapshot(
      query(
        collection(db, 'streams', streamId, 'processedMessages'),
        orderBy('time'),
        limitToLast(20)
      ),
      (querySnapshot) => {
        querySnapshot.docChanges().forEach((change) => {
          if (change.type === 'added') {
            const document = {
              ...change.doc.data(),
              id: change.doc.id
            };

            if (
              document?.name === userName
              && document?.userId === activeUserId
            ) { return; }

            if (featureType.current !== document?.interaction) return;

            setMessages((prev) => [...prev, document]);
          }
        });
      }
    );

    return () => {
      unSubMessages();
    };
  }, [streamId]);

  const {
    register,
    handleSubmit,
    setFocus,
    reset,
    watch
  } = useForm({
    mode: 'onChange',
    resolver: yupValidator(
      yup.object().shape({
        chat_input: yup.string().required()
      })
    )
  });

  useEffect(() => {
    setFocus('chat_input');
  }, []);

  const handleComment = (data) => {
    pushDataLayerForEvent('sandbox_zoom_chatbox_send_message', { streamId }, false);
    onComment(data);
    reset({ chat_input: '' });
    setFocus('chat_input');
  };

  useEffect(() => {
    setMessages([]);
  }, [featureId]);

  const input = watch('chat_input');

  return (
    <div className='w-full flex flex-col text-white bg-[#382537]'>
      <div className='pt-4 px-4 flex flex-col gap-2'>
        <p className="font-bold text-base text-[#F1F1F1]">Chat</p>
        <p
          className="font-normal text-xs "
          style={{
            color: 'rgba(241, 241, 241, 0.60)'
          }}
        >
          Enjoy interacting with the people in this webinar!
        </p>
      </div>

      <ScrollableFeed className='p-4 flex-1 scrollbar-hide'>
        {messages.map((message) => (
          <Message key={message.id} message={message} />
        ))}
      </ScrollableFeed>

      <div className='p-2'>
        <form
          onSubmit={handleSubmit(handleComment)}
          className='p-2 rounded-ee-lg'>
          <div className='relative'>
            <Textarea
              autoFocus
              value={input}
              autoComplete='off'
              disableAnimation
              {...register('chat_input')}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                  handleSubmit(handleComment)();
                }
              }}
              className="w-full font-normal text-sm bg-[#291D27] md:leading-5"
              placeholder='What would you like to share?'
            />
            {showInputPulse && isHotspotEnabled && (
              <div className='absolute -top-3 left-28'>
                <Pulse
                  isEnabled={isHotspotEnabled}
                  onClose={handleHotspotClose}
                  tooltip='Please type your response here; it will reflect in the interaction.'
                />
              </div>
            )}
          </div>

          <input type="submit" hidden />

        </form>
      </div>
    </div>
  );
};

const Message = ({ message }) => {
  const backgroundColor = stringToColor(message.name);
  return (
    <div className='my-2 flex flex-col gap-2'>
      <div className='flex items-center gap-2'>
        <p style={{
          backgroundColor,
          color: getFontColor(backgroundColor)
        }} className='rounded-full h-9 w-9 flex bg-red-900 items-center justify-center text-center'>{message.name?.at(0)}</p>
        <div className='flex flex-col'>
          <p className='text-[#F1F1F199]/60 text-xs'>{message.name}</p>
          <p className='text-[#F1F1F199]/60 text-xs'>{moment(message.time).fromNow()}</p>
        </div>
      </div>
      <div className='ml-[44px] flex flex-col font-medium bg-[#9580FF] p-3 rounded-lg rounded-tl-none w-fit' >
        <p
          style={{ wordWrap: 'anywhere' }}
          className='font-medium text-xs'
        >
          {message.message}
        </p>
      </div>
    </div>
  );
};

export default ChatBox;
