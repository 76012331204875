import { useState } from 'react';
import Button from './Button.new';
import Tooltip from './new/tooltip';

interface PulseProps {
  tooltip: string;
  isEnabled?: boolean;
  onClose?: () => void;
}

export const Pulse = ({ tooltip = '', isEnabled = true, onClose = () => {} }: PulseProps) => {
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  const handleClose = () => {
    setIsTooltipOpen(false);
    onClose();
  };

  if (!isEnabled) return null;

  return (
    <Tooltip isOpen={isTooltipOpen} content={
      <div className='flex flex-col gap-2'>
        <p>{tooltip}</p>
        <Button onClick={handleClose}>Got it</Button>
      </div>
    }>
      <div onMouseEnter={() => setIsTooltipOpen(true)} className="pulse cursor-pointer" />
    </Tooltip>
  );
};
