import {
  useRef,
  useState,
  useEffect
} from 'react';
import moment from 'moment';
import * as yup from 'yup';
import db from '@services/firebase-service';
import { yupValidator } from '@lib/yup-validator';
import {
  query,
  addDoc,
  orderBy,
  onSnapshot,
  collection,
  limitToLast
} from 'firebase/firestore';
import { useForm } from 'react-hook-form';
import { pushDataLayerForEvent } from '@lib/gtag';
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import { getFontColor } from '@components/Features/talkingTiles/helper';
import { stringToColor } from '../Youtube/ChatBox';
import { Pulse } from '@atoms/Pulse';
import { useHotspot } from '@lib/hooks/useHotSpot';
import ScrollableFeed from 'react-scrollable-feed';
import SendRoundedIcon from '@mui/icons-material/SendRounded';
import { Switch, Input, Avatar } from '@nextui-org/react';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import Image from 'next/image';

const ChatBox = ({ streamId, featureId, interactionType, showInputPulse }) => {
  const { isHotspotEnabled, handleHotspotClose } = useHotspot('sandbox:chat-input:hotspot');
  const [messages, setMessages] = useState([]);
  const userName = 'You';
  const activeUserId = 'user';
  const featureType = useRef(null);
  const [overlay, setOverlay] = useState(false);

  useEffect(() => {
    featureType.current = interactionType;
  }, [interactionType]);

  // eslint-disable-next-line camelcase
  const onComment = async ({ chat_input }) => {
    const rawMessagesRef = collection(db, 'streams', streamId, 'rawMessages');

    const document = {
      upvotes: 0,
      // eslint-disable-next-line camelcase
      message: chat_input,
      time: Date.now(),
      userId: activeUserId,
      name: userName,
      interaction: interactionType
    };

    setMessages((prev) => [...prev, document]);

    await addDoc(rawMessagesRef, document);
  };

  useEffect(() => {
    if (!streamId) return undefined;

    const unSubMessages = onSnapshot(
      query(
        collection(db, 'streams', streamId, 'processedMessages'),
        orderBy('time'),
        limitToLast(20)
      ),
      (querySnapshot) => {
        querySnapshot.docChanges().forEach((change) => {
          if (change.type === 'added') {
            const document = {
              ...change.doc.data(),
              id: change.doc.id
            };

            if (
              document?.name === userName
              && document?.userId === activeUserId
            ) { return; }

            if (featureType.current !== document?.interaction) return;

            setMessages((prev) => [...prev, document]);
          }
        });
      }
    );

    return () => {
      unSubMessages();
    };
  }, [streamId]);

  const {
    register,
    handleSubmit,
    setFocus,
    reset,
    watch
  } = useForm({
    mode: 'onChange',
    resolver: yupValidator(
      yup.object().shape({
        chat_input: yup.string().required()
      })
    )
  });

  useEffect(() => {
    setFocus('chat_input');
  }, []);

  const handleComment = (data) => {
    pushDataLayerForEvent('sandbox_zoom_chatbox_send_message', { streamId }, false);
    onComment(data);
    reset({ chat_input: '' });
    setFocus('chat_input');
  };

  useEffect(() => {
    setMessages([]);
  }, [featureId]);

  const input = watch('chat_input');

  return (
    <div className='w-full flex flex-col text-white bg-[#0F2347]'>
      <div className='py-4 px-5 flex justify-between items-center border-b border-[#25395A]'>
        <p className="font-bold text-lg">Chat</p>
      </div>

      <div className='px-5 py-2 flex items-center cursor-not-allowed'>
        <Switch
          checked={overlay}
          onChange={() => setOverlay(!overlay)}
          size="sm"
          isDisabled
        />
        <span className="ml-2 text-sm">Overlay</span>
      </div>

      <ScrollableFeed className='flex-1 scrollbar-hide'>
        {messages.map((message) => (
          <Message key={message.id} message={message} />
        ))}
      </ScrollableFeed>

      <div className='p-2 border-t border-[#25395A]'>
        <form
          onSubmit={handleSubmit(handleComment)}
          className='rounded-ee-lg'>
          <div className='relative'>
            <Input
              autoFocus
              size='sm'
              value={input}
              classNames={{
                inputWrapper: 'border-none data-[focus=true]:border-none',
                innerWrapper: 'border-none data-[focus=true]:border-none outline-none'
              }}
              autoComplete='off'
              startContent={(
                <div className='flex gap-1 text-white'>
                  <div className='flex items-center justify-center rounded-full font-bold bg-black w-6 h-6'>R</div>
                </div>
              )}
              disableAnimation
              endContent={(
                <button type='submit'>
                  <SendRoundedIcon fontSize='small' htmlColor='#5F6A83' />
                </button>
              )}
              {...register('chat_input')}
              style={{ color: '#54617D', fontSize: '15px' }}
              className="w-full font-normal text-neutral-60 md:leading-5"
              placeholder='Type message here...'
              variant='underlined'
            />
            {showInputPulse && isHotspotEnabled && (
              <div className='absolute -top-3 left-28'>
                <Pulse
                  isEnabled={isHotspotEnabled}
                  onClose={handleHotspotClose}
                  tooltip='Please type your response here; it will reflect in the interaction.'
                />
              </div>
            )}
          </div>

          <input type="submit" hidden />

        </form>
      </div>
    </div>
  );
};

const Message = ({ message }) => (
  <div className='my-2 flex gap-2'>
    <Image
      className='rounded-full max-h-[40px] max-w-[40px]'
      alt='user'
      src='/images/sandbox/restream/user.png'
      width={40}
      height={40}
    />

    <div className='w-full'>
      <div className='flex flex-col font-medium bg-[#253859] px-3 py-2 rounded-lg w-full ' >
        <div className='flex justify-between'>
          <p className='text-[#B1B7C3] text-sm'>{message.name}</p>
          <p className='text-[#70819E] text-xs font-normal'>{moment(message.time).format('HH:mm:ss')}</p>
        </div>
        <p
          style={{ wordWrap: 'anywhere' }}
          className='font-medium text-white text-[15px]'
        >
          {message.message}
        </p>
      </div>

    </div>
  </div>
);

export default ChatBox;
