import React from 'react';

const Notification = ({ platformName }) => {
  const platformImages = [
    { name: 'Zoom', src: '/images/zoom-chat.png', height: '60px' },
    { name: 'Google Meet', src: '/images/google-meet-chat.png', height: '60px' },
    { name: 'Teams', src: '/images/teams-chat.png', className: 'h-16' },
  ];

  return (
    <div className='absolute z-40 inset-0 flex shadow-lg animaiton-shake h-fit w-fit m-auto'>
      <div className='bg-red-700/80 shadow border border-red-700 rounded-[32px] m-auto w-[620px] h-[44vh] max-h-[350px] text-white flex flex-col items-center justify-center p-8'>
        <div className='flex flex-col items-center justify-center gap-4'>
          {/* <SmsOutlinedIcon className='text-white/85' style={{ fontSize: '40px' }} /> */}
          <p className="text-2xl drop-shadow-sm">
            Just type in the <span className="font-bold">{platformName} chat</span>
          </p>
          <div className="relative mt-4">
            {typeof platformName === 'string' &&
              platformImages.map(
                (platform) =>
                  platformName.match(platform.name) && (
                    <img key={platform.name} className={`${platform.className ? platform.className : `h-[${platform.height}]`}`} src={platform.src} alt={`${platform.name} chat`} />
                  )
              )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Notification;
