import * as yup from 'yup';
import { useEffect } from 'react';
import { MdSend } from 'react-icons/md';
import { useForm } from 'react-hook-form';
import { yupValidator } from '@lib/yup-validator';
import { pushDataLayerForEvent } from '@lib/gtag';

const InteractionInputBox = ({
  streamId,
  onComment,
  activeInteraction
}) => {
  const {
    register, handleSubmit, setFocus, reset, formState: { isValid }
  } = useForm({
    mode: 'onChange',
    resolver: yupValidator(
      yup.object().shape({
        chat_input: yup.string().required()
      })
    )
  });

  useEffect(() => {
    setFocus('chat_input');
  }, []);

  const handleComment = (data) => {
    onComment(data);
    pushDataLayerForEvent('sandbox_ipa_chatbox_send_message', { streamId }, false);
    reset({ chat_input: '' });
    setFocus('chat_input');
  };

  return (
    <form
      onSubmit={handleSubmit(handleComment)}
      className="w-full flex items-center p-2 bg-black"
    >
      <input
        tabIndex={1}
        autoComplete='off'
        placeholder={activeInteraction ? 'Your Answer' : 'Chat here'}
        {...register('chat_input')}
        className="border-focused w-full h-10 font-Poppins font-normal rounded-lg border-1 border-neutral-30 text-neutral-60 text-lg leading-6 px-3"
      />

      <button tabIndex={1} type='submit'>
        <MdSend color={isValid ? '#FF0064' : '#D1D1DB'} className="border-focused px-1 rounded w-8 h-8 ml-2 md:ml-4 cursor-pointer bg-white" />
      </button>
    </form>
  );
};

export default InteractionInputBox;
