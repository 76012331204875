import InfoIcon from '@mui/icons-material/InfoRounded';
import CloseIcon from '@mui/icons-material/CloseRounded';

const NoticeBubble = ({
  visible,
  message,
  onClose,
  position = 'bottom-left'
}) => {
  if (!visible) return null;

  const positionMap = {
    'bottom-left': 'left-3 bottom-3',
    'bottom-right': 'right-0 bottom-0',
    'top-left': 'left-0 top-0',
    'top-right': 'right-0 top-0'
  };

  const positionClass = positionMap[position];

  return (
    <div className={`z-50 fixed ${positionClass} left-3 justify-between p-2 flex w-100 rounded-md items-start`}
      style={{
        backgroundColor: '#FFA940',
        color: '#212121'
      }}>
      <div className='p-1'>
        <InfoIcon fontSize='large' />
      </div>
      <div className='font-normal text-2xl p-1'>
        {message}
      </div>
      <div className='p-1'>
        <CloseIcon fontSize='large' onClick={onClose} />
      </div>
    </div>
  );
};

export default NoticeBubble;
