import { isNil } from 'lodash';
import SvgIconBox from '@atoms/SvgIconBox';
import EmptyScreenBanner from '@components/Sandbox/Ipa/chat/EmptyScreenBanner';
import InteractionInputBox from '@components/Sandbox/Ipa/chat/InteractionInputBox';
import InteractionScreenBanner from '@components/Sandbox/Ipa/chat/InteractionScreenBanner';

import Wordcloud from '../../../../public/images/InteractionPage/dynamic-svg/wordcloud.svg';
import Map from '../../../../public/images/InteractionPage/dynamic-svg/map.svg';
import Thoughts from '../../../../public/images/InteractionPage/dynamic-svg/thoughts.svg';
import Poll from '../../../../public/images/InteractionPage/dynamic-svg/pollff.svg';
import EmojisEverywhere from '../../../../public/images/InteractionPage/dynamic-svg/emojiEverywhere.svg';
import TalkingTiles from '../../../../public/images/InteractionPage/dynamic-svg/talkingTiles.svg';
import PollWithOptions from '../../../../public/images/InteractionPage/dynamic-svg/poll.svg';

const ChatFooter = ({ onComment, activeInteraction }) => {
  const getIcon = (type) => {
    switch (type) {
      case 'map': return <SvgIconBox svg={<Map
        className={'fill-current text-interactionIcons-MagicMap'} width="24" />}
      className='bg-neutral-80' />;
      case 'wordCloud': return <SvgIconBox svg={<Wordcloud
        className={'fill-current text-interactionIcons-WonderWords'} width="24" />}
      className='bg-neutral-80' />;

      case 'transientThought': return <SvgIconBox svg={<Thoughts
        className={'fill-current text-interactionIcons-TransientThoughts'} width="24" />}
      className='bg-neutral-80' />;

      case 'talkingTiles': return <SvgIconBox svg={<TalkingTiles
        className={'fill-current text-interactionIcons-TalkingTiles'} width="24" />}
      className='bg-neutral-80' />;

      case 'emojisEverywhere': return <SvgIconBox svg={<EmojisEverywhere
        className={'fill-current text-interactionIcons-EmojisEverywhere'} width="24" />}
      className='bg-neutral-80' />;

      case 'freeForm': return <SvgIconBox svg={<Poll
        className={'fill-current text-interactionIcons-PollWithOutOptions'} width="24" />}
      className='bg-neutral-80' />;

      case 'pollWithOptions': return <SvgIconBox svg={<PollWithOptions
        className={'fill-current text-interactionIcons-PollWithOutOptions'} width="24" />}
      className='bg-neutral-80' />;

      default: return <></>;
    }
  };

  const Interactions = {
    freeForm: {
      Icon: getIcon('freeForm'),
      name: 'Power Poll',
      hint: '* Type 1 or 2 or 3 ... in the chat'
    },
    pollWithOptions: {
      Icon: getIcon('pollWithOptions'),
      name: 'Power Poll',
      hint: activeInteraction?.engagement?.settings?.OptionsList === 'number' ? '* Type 1 or 2 or 3 ... in the chat' : '* Type A or B or C ... in the chat'
    },
    wordCloud: {
      Icon: getIcon('wordCloud'),
      name: 'Wonder Words',
      hint: ''
    },
    map: {
      Icon: getIcon('map'),
      name: 'Magic Map',
      hint: 'Type locations in the chat'
    },
    transientThought: {
      Icon: getIcon('transientThought'),
      name: 'Transient Thoughts',
      hint: 'TYPE YOUR THOUGHTS OUT'
    },
    talkingTiles: {
      Icon: getIcon('talkingTiles'),
      name: 'Talking Tiles',
      hint: 'TYPE YOUR THOUGHTS OUT'
    },
    emojisEverywhere: {
      Icon: getIcon('emojisEverywhere'),
      name: 'Emojis Everywhere',
      hint: ''
    }
  };

  const interactionDetails = Interactions[activeInteraction?.type];

  return (
    <>
      {isNil(activeInteraction)
        || !activeInteraction?.type
        || isNil(Interactions[activeInteraction?.type])
        ? (
          <EmptyScreenBanner />
        ) : (
          <InteractionScreenBanner
            activeInteraction={activeInteraction}
            interactionDetails={interactionDetails}
          />
        )}
      <InteractionInputBox
        tabIndex={1}
        onComment={onComment}
        activeInteraction={activeInteraction}
      />
    </>
  );
};

export default ChatFooter;
