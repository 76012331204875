import Image from '@atoms/Image';

const ChatHeader = () => (
  <div className="bg-black z-50">
    <div className="flex items-center justify-center py-2">
      <Image className="max-w-70 max-h-16" src='/images/sandbox/logo_small.svg' />
    </div>
    <div className="flex items-center justify-between m-4">
      <div className="flex gap-2">
        <Image src="/images/sandbox/new-_small_people_mobile.svg" />
        <p className="font-medium text-base leading-4 text-white mt-2">Event Title</p>
      </div>
      <div className="flex gap-x-2">
        <Image src="/images/sandbox/3-User.svg" />
        <p className="font-medium text-tiny leading-4 text-white ">
            5.2k
        </p>
      </div>
    </div>
  </div>
);

export default ChatHeader;
