import Image from '@atoms/Image';
import ChatBox from '@components/Sandbox/Streamyard/ChatBox';
import { getOnFeature } from '@services/feature-service';
import EngagementMeter from '@components/EngagementMeter';
import NextPrevCapsule from '@components/RunOfShow/NextPrevCapsule';
import InteractionTitle from '@components/Sandbox/Title';
import { useHotspot } from '@lib/hooks/useHotSpot';
import Notification from '@components/Notification';
import { platformName } from '@services/utils.service';
import { useEffect, useState } from 'react';
import { Spinner } from '@atoms/new/spinner';
import NextImage from 'next/image';
import useGetImageProperties from '@components/Presentation/hooks/useGetImageProperties';
import { getColorsFromInteraction } from '../sandbox.helper';

const StreamyardSandbox = ({
  feature,
  stream,
  stopFeature,
  currentRosResult,
  next,
  linkQuestions,
  currentIndex,
  featureNextState,
  featurePrevState,
  prevRef,
  nextRef,
  isAiSandbox = false,
  isGeneratingResponses = false
}) => {
  const { isHotspotEnabled, handleHotspotClose } = useHotspot('sandbox:next/prev:button:hotspot');

  const isSlide = feature?.type === 'PDF Slide';

  const disableNotificationFor = (type = '') => ['PDF Slide', 'wheel', 'newWheel'].includes(type);
  const [showNotification, setShowNotification] = useState(false);

  useEffect(() => {
    if (!feature) return;
    setShowNotification(true);
  }, [feature]);

  // get background image settings
  const { getImageProperties } = useGetImageProperties();
  const {
    src: bgImgSrc,
    opacity: bgImgOpacity = 100,
  } = getImageProperties(feature?.type, feature?.setting);

  const { bgColor = '#111111', headingColor = '#FFFFFF' } = getColorsFromInteraction(feature?.type, feature?.color);

  return (
    <div className="flex rounded-lg border border-grey-200 max-h-[720px] overflow-hidden" style={{ background: '#202124', height: '80vh' }}>

      <div
        style={{ background: bgColor }}
        className="w-[75%] rounded-s-lg flex flex-col justify-between relative">
        <Image
          style={{ height: '55px' }}
          className="w-full cursor-not-allowed rounded-tl z-[2]"
          src="/images/sandbox/streamyard/header.png"
          alt={'teams-top-app-bar'}
        />
        {bgImgSrc && <NextImage
          src={`${process.env.NEXT_PUBLIC_BUCKET_URL}${bgImgSrc}`}
          fill
          style={{ opacity: bgImgOpacity / 100 }}
          alt='background img'
        />}
        {
          isGeneratingResponses && (
            <div className='w-full h-full absolute z-50 flex items-center justify-center bg-[#D9D9D980]/50 top-0 left-0 bottom-6 right-0'>
              <Spinner />
            </div>
          )
        }
        {
          !isSlide && (
            <div className='z-[2] rounded-tl-lg rounded-tr-lg flex items-center' style={{ height: '100px' }}>
              <div className="pt-4 pb-3 pl-6 w-full">
                <InteractionTitle title={feature?.title}
                  type={feature?.type}
                  isLogoRequired={false}
                  logoUrl={'/images/logos/logo_white.svg'}
                  color={headingColor}
                />
              </div>
            </div>
          )
        }
        <div
          id="hero"
          className='p-2 flex-1 h-[60%] relative z-[2]'
        >
          {showNotification && !disableNotificationFor(feature?.type) && <Notification platformName={platformName.streamyard} />}

          {feature && getOnFeature({
            data: [],
            setShowNotification,
            streamId: stream.id,
            interactionType: {
              isResult: false,
              isRos: true
            },
            setShowRosModel: () => { },
            setCurrentFeatureId: () => { },
            dispatchTab: () => { },
            setInteractionState: () => { },
            setShowToast: () => { },
            stopFeature,
            currentRosResult,
            startInteraction: true,
            currentRosFeature: feature,
            platformType: stream?.platform_type,
            streamName: stream?.title,
            moderationModeRef: { current: null },
            activeInteractionRef: { current: null },
            meta: null,
            updateFSMeta: () => { },
            pushToFS: () => { },
            pushCmdToFS: () => { },
            isSandbox: true,
            setStartTime: () => { }
          })}
        </div>
        <div className='h-18 flex items-center px-2 py-2 dark:bg-neutral-80'>
          {!!linkQuestions && (
            <div className='relative items-center justify-center'>
              {isAiSandbox && currentIndex === 0 && (
                <img
                  src='/images/sandbox/click-here.png'
                  className='absolute bottom-[60px] left-[60px] w-32'
                />
              )}
              <NextPrevCapsule
                {...{
                  next,
                  pulseProps: {
                    tooltip: 'Click here to proceed to the next slide',
                    isEnabled: isHotspotEnabled,
                    onClose: handleHotspotClose
                  },
                  showTotal: true,
                  linkQuestions,
                  currentIndex,
                  featureNextState,
                  featurePrevState,
                  prevRef,
                  nextRef,
                  streamIndex: 0,
                  isTrack: false
                }}
              />
            </div>
          )}

          {stream?.id && <div className='relative -rotate-90 -top-6 -left-52 hidden'>
            <EngagementMeter streamId={stream?.id} id="engMeterCtdId" />
          </div>}
        </div>
      </div>
      <div className="relative flex w-[30%] rounded-e-lg border-l border-grey-200">
        {isAiSandbox && currentIndex === 1 && (
          <img
            src='/images/sandbox/website/big-arrow.png'
            className='absolute bottom-0 right-[75%] z-20 w-64'
          />
        )}
        <ChatBox
          streamId={stream?.id}
          featureId={feature?.id}
          interactionType={feature?.type}
          showInputPulse={currentIndex >= 1}
        />

        <Image
          style={{ width: '96px' }}
          className='cursor-not-allowed rounded-r-lg max-h-[720px] h-[80vh]'
          src="/images/sandbox/streamyard/right-side-bar.png"
          alt={'streamyard-right-side-bar'}
        />
      </div>
    </div>
  );
};

export default StreamyardSandbox;
