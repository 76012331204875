export const nonInteractiveFeatures = [
  'Quick Questions',
  'PDF Slide',
  'wheel',
  'newWheel',
  'linkLibrary',
  'textTrack',
  'fantasticFans',
  'blankScreen'
];
