import { type InteractionColors, type InteractionType } from '@components/Presentation/types';

const getColorsFromInteraction = (type: InteractionType, colors: InteractionColors) => {
  switch (type) {
    case 'pollWithOptions':
      return { bgColor: colors.pollWithOptionsBgColor, headingColor: colors.pollWithOptionsHeadingColor };
    case 'freeForm':
      return { bgColor: colors.pollBgColor, headingColor: colors.pollHeadingColor };
    case 'map':
      return { bgColor: colors.mapBgColor, headingColor: colors.mapHeadingColor };
    case 'wheel':
      return { bgColor: colors.choiceCircleBgColor, headingColor: colors.choiceCircleHeadingColor };
    case 'wordCloud':
      return { bgColor: colors.wcBgColor, headingColor: colors.wcHeadingColor };
    case 'emojisEverywhere':
      return { bgColor: colors.emojisEverywhereBgColor, headingColor: colors.emojisEverywhereHeadingColor };
    case 'talkingTiles':
      return { bgColor: colors.talkingTilesBgColor, headingColor: colors.talkingTilesHeadingColor };
    case 'transientThought':
      return { bgColor: colors.ttBgColor, headingColor: colors.ttHeadingColor };
    case 'newWheel':
      return { bgColor: colors.winningWheelBgColor, headingColor: colors.winningWheelHeadingColor };
    case 'linkLibrary':
      return { bgColor: colors.linkLibraryBgColor, headingColor: colors.linkLibraryHeadingColor };
    case 'Quick Questions':
      return { bgColor: colors.quickQuestionBgColor, headingColor: colors.quickQuestionHeadingColor };
    case 'textTrack':
      return { bgColor: colors.textTrackBgColor, headingColor: colors.textTrackHeadingColor };
    case 'ratingPolls':
      return { bgColor: colors.ratingPollsBgColor, headingColor: colors.ratingPollsHeadingColor };
    case 'fantasticFans':
      return { bgColor: colors.ffBgColor, headingColor: colors.ffHeadingColor };
    default:
      return { bgColor: '#111111', headingColor: '#FFFFFF' };
  }
};

export {
  getColorsFromInteraction
};
