import { Spinner as NextUISpinner } from '@nextui-org/spinner';
import { extendVariants } from '@nextui-org/react';

export const Spinner = extendVariants(NextUISpinner, {
  variants: {
    color: {
      zoomapp: {
        circle1: 'border-4 border-b-grey-400',
        circle2: 'border-4 border-b-grey-400',
      }
    }
  }
});
