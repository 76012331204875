import SvgIconBox from '@atoms/SvgIconBox';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import HoverBox from '@atoms/HoverBox';
import { Pulse } from '@atoms/Pulse';
import { getLiveLayoutColors } from './helper/presentationLive';

const NextPrevCapsule = ({
  linkQuestions,
  currentIndex,
  featurePrevState,
  featureNextState,
  prevRef,
  nextRef,
  isTrack,
  showTotal = true,
  pulseProps = null,
  layoutTheme = 'dark'
}) => {
  const colors = getLiveLayoutColors(layoutTheme);
  return (
    <>
      {
        (!isTrack && (currentIndex || currentIndex === 0)) && (
          <div className='flex relative'>
            <div
              id="nextPrevCtdId"
              className={`${colors.containerColor} flex items-center justify-center gap-2 rounded-full p-0.5 w-[120px]`}>
              <div
                id="prevCtdId"
                ref={prevRef}
                onClick={() => { if (currentIndex > 0) featurePrevState(true); }}
                className={`${currentIndex > 0 && 'hover:bg-grey-800 cursor-pointer'} flex rounded-full w-10`}
              >
                <HoverBox
                  id='tooltip'
                  variant='dark'
                  className={`${currentIndex > 0 && colors.hoverBg}  group flex items-center justify-center rounded-full w-10 h-10`}
                  svg={<ArrowBackIosNewRoundedIcon className={`${currentIndex === 0 ? 'text-neutral-90' : `${colors.svgColor}`} !transition-none`} fontSize='small' />}
                  position='top'
                  toolTipDescription='Previous slide'
                  disablePointer={currentIndex === 0}
                />
              </div>

              {showTotal && <div className='flex items-center'>
                <span className={`z-40 ${colors.textColor} whitespace-nowrap font-medium text-sm leading-3.5 w-5 text-center`}>
                  {currentIndex + 1}</span>
              </div>}

              <div id="nextCtdId"
                ref={nextRef}
                onClick={() => { if (currentIndex + 1 < linkQuestions.length) featureNextState(true); }}
                className={`${currentIndex + 1 < linkQuestions.length && 'hover:bg-grey-800 cursor-pointer'}  flex rounded-full w-10`}
              >
                <HoverBox
                  id='tooltip'
                  variant='dark'
                  className={`${currentIndex + 1 < linkQuestions.length && colors.hoverBg} group flex items-center justify-center rounded-full w-10 h-10`}
                  svg={<ArrowForwardIosRoundedIcon className={`${(currentIndex + 1 === linkQuestions.length) ? 'text-neutral-90' : ` ${colors.svgColor}`} !transition-none`} fontSize='small' />}
                  position='top'
                  toolTipDescription='Next slide'
                  disablePointer={currentIndex + 1 === linkQuestions.length}
                />
              </div>
            </div>
            {!!pulseProps && (
              <div className='absolute -top-2 right-2'>
                <Pulse {...pulseProps} />
              </div>
            )}
          </div>
        )
      }
      {
        (isTrack || currentIndex === undefined) && <div id="nextPrevCtdId" className="flex items-center justify-center gap-1 bg-grey-900 rounded-full p-0.5 w-[120px]">

          <div
            id="prevCtdId"
            className='flex items-center w-8'
          >
            <SvgIconBox svg={<ArrowBackIosNewRoundedIcon className='fill-current text-neutral-90' fontSize='small' />} className="w-8 h-8"
              disablePointer={true}
            />
          </div>

          <div className='flex items-center'>
            <span className={'z-50 text-neutral-90 whitespace-nowrap font-medium text-sm leading-3.5 w-5 text-center'}>
              {currentIndex + 1}
            </span>
          </div>

          <div
            className='flex items-center w-8'
          >
            <SvgIconBox svg={<ArrowForwardIosRoundedIcon className='fill-current text-neutral-90' fontSize='small' />} className="w-8 h-8"
              disablePointer={true}
            />
          </div>
        </div>

      }
    </>
  );
};

export default NextPrevCapsule;
