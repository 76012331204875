import Image from '@atoms/Image';
import moment from 'moment/moment';
import db from '@services/firebase-service';
import { doc, onSnapshot } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import { getLiveLayoutColors } from './RunOfShow/helper/presentationLive';

const EngagementMeter = ({
  streamId, id, widthParam = '8', heightParam = '8', isPresentationScreen = false, layoutTheme = 'dark'
}) => {
  let lastUpdated = moment().valueOf();

  const [metrics, setMetrics] = useState({
    hot: false, percent: 0, colorChangepercent: 0, maxAvgPast5secsVar: 0, totalAvg: 0, lastUpdated
  });

  const [height] = useState(heightParam);
  const [width] = useState(widthParam);

  const background = [
    <Image key="0" height={height} width={width} src={'/images/engagement-meter/0_l.svg'} />,
    <Image key="1" height={height} width={width} src={'/images/engagement-meter/0_m.svg'} />,
    <Image key="2" height={height} width={width} src={'/images/engagement-meter/0_m.svg'} />,
    <Image key="3" height={height} width={width} src={'/images/engagement-meter/0_m.svg'} />,
    <Image key="4" height={height} width={width} src={'/images/engagement-meter/0_m.svg'} />,
    <Image key="5" height={height} width={width} src={'/images/engagement-meter/0_r.svg'} />
  ];

  const levels = [
    <Image key="0" height={height} width={width} src={'/images/engagement-meter/0.svg'} />,
    <Image key="1" height={height} width={width} src={'/images/engagement-meter/1.svg'} />,
    <Image key="2" height={height} width={width} src={'/images/engagement-meter/2.svg'} />,
    <Image key="3" height={height} width={width} src={'/images/engagement-meter/3.svg'} />,
    <Image key="4" height={height} width={width} src={'/images/engagement-meter/4.svg'} />,
    <Image key="5" height={height} width={width} src={'/images/engagement-meter/5.svg'} />
  ];

  const buildLevels = (percent) => {
    const index = parseInt(Math.ceil(percent) / (100 / background.length), 10);
    if (index <= 0) {
      return background;
    }
    if (index > background.length) {
      return [...levels];
    }
    return [...levels].slice(0, index).concat([...background].slice(index, background.length));
  };

  useEffect(() => {
    const unSubStreamMetrics = onSnapshot(doc(db, 'streams', streamId, 'engagement', 'metrics'), (document) => {
      if (document.exists()) {
        setMetrics((prev) => {
          const { time: lastUpdatedByServer, metrics: newVal } = document.data();
          if (newVal.percent < prev.percent) {
            const diff = Math.round((prev.percent - newVal.percent) / 3);
            newVal.percent += diff;
          }

          if (moment().diff(lastUpdatedByServer, 'seconds') > 1.5) {
            newVal.percent = 0;
          }

          lastUpdated = moment().valueOf();
          return { ...newVal, lastUpdated };
        });
      }
    });
    return unSubStreamMetrics;
  }, []);

  const getMeter = ({ percent }) => {
    const meter = buildLevels(percent);
    return <>
      <div className="flex gap-1">
        {meter.map((level) => level)}
      </div>
    </>;
  };

  useEffect(() => {
    const timer = setInterval(() => {
      setMetrics((prev) => {
        if (moment().diff(prev.lastUpdated, 'seconds') < 1.5) return prev;

        if (prev.percent <= 0) return { ...prev, percent: 0 };

        return { ...prev, percent: prev.percent - (100 / background.length) };
      });
    }, 1500);

    return () => clearInterval(timer);
  }, []);

  return (
    <div
      id={id}
      className={`${getLiveLayoutColors(layoutTheme).containerColor} ${isPresentationScreen ? 'flex justify-center fixed bottom-[60px] rounded-full px-3 h-[24px] left-[48px] transform -rotate-90 origin-bottom-left' : 'p-3 rounded-full'}`}
    >
      {getMeter(metrics)}
    </div>);
};

export default EngagementMeter;
