import Text from '@atoms/Text';
import Image from '@atoms/Image';

const EmptyScreenBanner = () => (
  <div className=" w-full bg-black flex items-center  px-2 py-4" >
    <Image src={'/images/EmptyScreenBanner.svg'} className="w-12 h-full mr-2" />
    <div>
      <Text className="w-full font-medium text-base leading-5 line-clamp-2 text-white">
        Create your own chat-powered event
      </Text>
      <Text className="w-full font-medium text-base leading-5 line-clamp-2 text-white">
        at StreamAlive.com
      </Text>
    </div>
  </div>
);

export default EmptyScreenBanner;
